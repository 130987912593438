import { useLocation } from 'react-router'

import { userCompaniesSelector } from 'services/auth/slice'

import { AuthNavLink, AuthSubmenu } from 'components/AuthNavLink'
import { routes } from 'routes'

import { t } from 'i18n/translation'
import { useSelector } from 'react-redux'
import { isAuthorized } from '../../auth/AuthorizeService'
import useCurrentPath from '../../hooks/useCurrentPath'
import { getUserRolesByCompany } from '../../utils/helperMethods'

export const navLinkActive = (routes, path) => {
  return routes.includes(path)
}

export const submenuShow = (submenuPaths, path) => {
  return submenuPaths.includes(path)
}

const MainMenu = () => {
  const path = useCurrentPath()
  const userCompanies = useSelector(userCompaniesSelector)
  const location = useLocation()
  const myActivitySection = location?.state?.myActivity
  const auditSection = location?.state?.audit

  const folderRoutes = [
    routes.folder.path,
    routes.folderDetails.path,
    routes.folderDocument.path,
    routes.folderDocumentWithPdf.path,
    routes.folderDocumentWithPdfAndId.path,
    // routes.folderHistory.path,
    routes.folderEmail.path,
    routes.folderSef.path,
  ]

  const myActivitiesRoutes = [
    routes.myActivities.path,
    routes.newDocuments.path,
    routes.readyForControlDocuments.path,
    routes.readyForSendingDocuments.path,
    routes.rejectedDocuments.path,
    routes.sentDocuments.path,
    routes.undispatched.path,
    routes.undispatchedDocument.path,
    routes.undispatchedDocumentWithPdf.path,
    routes.undispatchedDocumentWithPdfAndId.path,
    routes.undispatchedHistory.path,
    routes.undispatchedDetails.path,
    routes.undispatchedEmail.path,
    routes.undispatchedInvoice.path,
  ]

  const auditRoutes = [routes.audit.path]

  const settingsRoutes = [
    routes.user.path,
    routes.settingsEmailTemplate.path,
    routes.channelSettings.path,
    routes.companySettings.path,
    routes.approvals.path,
    routes.approvalFlows.path,
    routes.triggers.path,
    routes.triggerFlows.path,
  ]

  const sefVatRecordingRoutes = [
    routes.groupVatRecording.path,
    routes.individualVatRecording.path,
    routes.groupVatRecordingDetails.path,
    routes.individualVatRecordingDetails.path,
  ]

  const helpRoutes = [routes.helpManual.path, routes.helpContact.path]
  const reportRoutes = [routes.reportEmail.path]
  const userRoles = getUserRolesByCompany(userCompanies)

  return (
    <nav className="sidebar nav flex-column">
      <AuthNavLink
        roles={routes.myActivities.roles}
        isAuthorized={isAuthorized(userRoles, routes.dashboard.roles)}
        active={navLinkActive([routes.dashboard.path], path)}
        to={routes.dashboard.path}
      >
        {t('scenes.dashboard.title')}
      </AuthNavLink>
      <AuthNavLink
        roles={routes.myActivities.roles}
        isAuthorized={isAuthorized(userRoles, routes.myActivities.roles)}
        active={navLinkActive(myActivitiesRoutes, path) || myActivitySection}
        to={routes.myActivities.path}
        state={{ isInitialLoad: true }}
      >
        {t('scenes.myActivities.title')}
      </AuthNavLink>
      <AuthNavLink
        roles={routes.audit.roles}
        isAuthorized={isAuthorized(userRoles, routes.audit.roles)}
        active={navLinkActive(auditRoutes, path) || auditSection}
        to={routes.audit.path}
      >
        {t('scenes.audit.title')}
      </AuthNavLink>
      <AuthNavLink
        roles={routes.folder.roles}
        isAuthorized={isAuthorized(userRoles, routes.folder.roles)}
        active={!myActivitySection && !auditSection && navLinkActive(folderRoutes, path)}
        to={routes.folder.path}
      >
        {t('scenes.folder.title')}
      </AuthNavLink>
      <AuthNavLink
        roles={routes.tenant.roles}
        isAuthorized={isAuthorized(userRoles, routes.tenant.roles)}
        active={navLinkActive([routes.tenant.path, routes.tenantCreate.path, routes.tenantDetails.path], path)}
        to={routes.tenant.path}
      >
        {t('scenes.tenant.title')}
      </AuthNavLink>
      <AuthNavLink
        isAuthorized={isAuthorized(userRoles, routes.activityLog.roles)}
        active={navLinkActive([routes.activityLogs.path, routes.activityLog.path], path)}
        to={routes.activityLogs.path}
      >
        {t('scenes.activityLog.title')}
      </AuthNavLink>

      <AuthSubmenu
        isAuthorized={isAuthorized(userRoles, routes.sefVatRecording.roles)}
        active={navLinkActive(sefVatRecordingRoutes, path)}
        show={submenuShow(sefVatRecordingRoutes, path)}
        title={t('scenes.sefVatRecording.title')}
        id="sefVatRecordingsSubmenu"
      >
        <AuthNavLink
          roles={routes.groupVatRecording.roles}
          isAuthorized={isAuthorized(userRoles, routes.groupVatRecording.roles)}
          active={navLinkActive([routes.groupVatRecording.path, routes.groupVatRecordingDetails.path], path)}
          to={routes.groupVatRecording.path}
        >
          {t('scenes.sefVatRecording.groupVatRecording.title')}
        </AuthNavLink>
        <AuthNavLink
          roles={routes.individualVatRecording.roles}
          isAuthorized={isAuthorized(userRoles, routes.individualVatRecording.roles)}
          active={navLinkActive([routes.individualVatRecording.path, routes.individualVatRecordingDetails.path], path)}
          to={routes.individualVatRecording.path}
        >
          {t('scenes.sefVatRecording.individualVatRecording.title')}
        </AuthNavLink>
      </AuthSubmenu>

      <AuthSubmenu
        roles={routes.reportEmail.roles}
        isAuthorized={isAuthorized(userRoles, routes.reportEmail.roles)}
        show={submenuShow(reportRoutes, path)}
        title={t('scenes.report.title')}
        id="reportsSubmenu"
      >
        <AuthNavLink
          roles={routes.reportEmail.roles}
          isAuthorized={isAuthorized(userRoles, routes.reportEmail.roles)}
          active={navLinkActive([routes.reportEmail.path], path)}
          to={routes.reportEmail.path}
        >
          {t('scenes.report.email')}
        </AuthNavLink>
      </AuthSubmenu>
      <AuthNavLink
        roles={routes.customer.roles}
        isAuthorized={isAuthorized(userRoles, routes.customer.roles)}
        active={navLinkActive([routes.customer.path], path)}
        to={routes.customer.path}
      >
        {t('scenes.customer.title')}
      </AuthNavLink>
      <AuthSubmenu
        roles={routes.settings.roles}
        isAuthorized={isAuthorized(userRoles, routes.settings.roles)}
        show={submenuShow(settingsRoutes, path)}
        title={t('scenes.settings.title')}
        id="settingsSubmenu"
      >
        <AuthNavLink
          roles={routes.user.roles}
          isAuthorized={isAuthorized(userRoles, routes.user.roles)}
          active={navLinkActive([routes.user.path], path)}
          to={routes.user.path}
        >
          {t('scenes.user.title')}
        </AuthNavLink>
        <AuthNavLink
          roles={routes.companySettings.roles}
          isAuthorized={isAuthorized(userRoles, routes.companySettings.roles)}
          active={navLinkActive([routes.companySettings.path], path)}
          to={routes.companySettings.path}
        >
          {t('scenes.settings.companySettings')}
        </AuthNavLink>
        <AuthNavLink
          roles={routes.channelSettings.roles}
          isAuthorized={isAuthorized(userRoles, routes.channelSettings.roles)}
          active={navLinkActive([routes.channelSettings.path], path)}
          to={routes.channelSettings.path}
        >
          {t('scenes.settings.channelSettings.title')}
        </AuthNavLink>
        <AuthNavLink
          roles={routes.approvals.roles}
          isAuthorized={isAuthorized(userRoles, routes.approvals.roles)}
          active={navLinkActive([routes.approvals.path, routes.approvalFlows.path], path)}
          to={routes.approvals.path}
        >
          {t('scenes.approvalFlows.title')}
        </AuthNavLink>
        <AuthNavLink
          roles={routes.triggers.roles}
          isAuthorized={isAuthorized(userRoles, routes.triggers.roles)}
          active={navLinkActive([routes.triggers.path, routes.triggerFlows.path], path)}
          to={routes.triggers.path}
        >
          {t('scenes.triggers.title')}
        </AuthNavLink>
        <AuthNavLink
          roles={routes.settingsEmailTemplate.roles}
          isAuthorized={isAuthorized(userRoles, routes.settingsEmailTemplate.roles)}
          active={navLinkActive([routes.settingsEmailTemplate.path], path)}
          to={routes.settingsEmailTemplate.path}
        >
          {t('scenes.settings.emailTemplate')}
        </AuthNavLink>
      </AuthSubmenu>
      <AuthSubmenu
        roles={routes.help.roles}
        isAuthorized={isAuthorized(userRoles, routes.help.roles)}
        show={submenuShow(helpRoutes, path)}
        title={t('scenes.help.title')}
        id="helpSubmenu"
      >
        <AuthNavLink
          roles={routes.helpManual.roles}
          isAuthorized={isAuthorized(userRoles, routes.helpManual.roles)}
          active={navLinkActive([routes.helpManual.path], path)}
          to={routes.helpManual.path}
        >
          {t('scenes.help.manual')}
        </AuthNavLink>
        <AuthNavLink
          roles={routes.helpContact.roles}
          isAuthorized={isAuthorized(userRoles, routes.helpContact.roles)}
          active={navLinkActive([routes.helpContact.path], path)}
          to={routes.helpContact.path}
        >
          {t('scenes.help.contactSupport')}
        </AuthNavLink>
      </AuthSubmenu>
      <AuthNavLink
        roles={routes.userManual.roles}
        isAuthorized={isAuthorized(userRoles, routes.userManual.roles)}
        active={navLinkActive([routes.userManual.path], path)}
        to={routes.userManual.path}
      >
        {t('scenes.userManual.title')}
      </AuthNavLink>
    </nav>
  )
}

export default MainMenu
