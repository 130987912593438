import React, { lazy } from 'react'

import AuthorizeRoute from 'auth/AuthorizeRoute'
import { routes } from 'routes'

import { createBrowserRouter } from 'react-router-dom'
import { AppLayout } from '../components/AppLayout'
import { HomepageLayout } from '../components/HomePageLayout'
import ErrorElement from 'components/ErrorElement'
import { FeatureFlags } from 'utils/constants'

const Home = lazy(() => import('scenes/Home'))
const Login = lazy(() => import('scenes/Login'))
const Tenant = lazy(() => import('scenes/Tenant'))
const CreateTenant = lazy(() => import('scenes/Tenant/components/CreateTenant'))
const TenantDetails = lazy(() => import('scenes/Tenant/components/Details'))
const User = lazy(() => import('scenes/User'))
const Document = lazy(() => import('scenes/Document'))
const Folder = lazy(() => import('scenes/Folder'))
const FolderDetails = lazy(() => import('scenes/Folder/components/FolderDetails'))
const FolderSef = lazy(() => import('scenes/Folder/components/FolderSef'))
const UndispatchedDetails = lazy(() => import('scenes/Folder/components/FolderDetails'))
const UndispatchedInvoice = lazy(() => import('scenes/Folder/components/FolderSef'))
const NotFound = lazy(() => import('components/NotFound'))
const Approvals = lazy(() => import('scenes/ApprovalFlows'))
const ApprovalFlows = lazy(() => import('scenes/ApprovalFlows/components/Approvals'))
const CompanySettings = lazy(() => import('scenes/CompanySettings'))
const UndispatchedDocument = lazy(() => import('scenes/Document'))
const Email = lazy(() => import('scenes/Email'))
const Reject = lazy(() => import('scenes/Email/components/Reject'))
const EmailTemplate = lazy(() => import('scenes/EmailTemplate'))
const FolderWrapper = lazy(() => import('scenes/Folder/components/FolderWrapper'))
const Contact = lazy(() => import('scenes/Help/Contact'))
const Manual = lazy(() => import('scenes/Help/Manual'))
const MyActivities = lazy(() => import('scenes/MyActivities'))
const NewDocuments = lazy(() => import('scenes/MyActivities/Tabs/New'))
const ReadyForControlDocuments = lazy(() => import('scenes/MyActivities/Tabs/ReadyForControl'))
const ReadyForSendingDocuments = lazy(() => import('scenes/MyActivities/Tabs/ReadyForSending'))
const RejectedDocuments = lazy(() => import('scenes/MyActivities/Tabs/Rejected'))
const SentDocuments = lazy(() => import('scenes/MyActivities/Tabs/Sent'))
const Undispatched = lazy(() => import('scenes/MyActivities/Tabs/Undispatched'))
const Report = lazy(() => import('scenes/Report'))
const ChannelSettings = lazy(() => import('scenes/ChannelSettings'))
const Triggers = lazy(() => import('scenes/Triggers'))
const TriggerFlows = lazy(() => import('scenes/Triggers/components/TriggerFlows'))
const UserManual = lazy(() => import('scenes/UserManual'))

const Dashboard = lazy(() => import('scenes/Dashboard'))
const ActivityLogs = lazy(() => import('scenes/ActivityLogs'))
const Customer = lazy(() => import('scenes/Customer'))
const ActivityLog = lazy(() => import('scenes/ActivityLogs/ActivityLog'))
const Audit = lazy(() => import('scenes/Audit'))

const IndividualVatRecording = lazy(() => import('scenes/IndividualVatRecording'))
const GroupVatRecording = lazy(() => import('scenes/GroupVatRecording'))

const IndividualVatRecordingDetails = lazy(() => import('scenes/IndividualVatRecording/components/Details'))
const GroupVatRecordingDetails = lazy(() => import('scenes/GroupVatRecording/components/Details'))

export const router = createBrowserRouter([
  {
    path: routes.root.path,
    element: <AppLayout />,
    errorElement: <ErrorElement />,
    children: [
      {
        index: true,
        element: <AuthorizeRoute roles={routes.helpManual.roles} component={Home} />,
      },
      {
        path: routes.tenant.path,
        element: <AuthorizeRoute roles={routes.tenant.roles} component={Tenant} />,
      },
      {
        path: routes.tenantCreate.path,
        element: <AuthorizeRoute roles={routes.tenantCreate.roles} component={CreateTenant} />,
      },
      {
        path: routes.tenantDetails.path,
        element: <AuthorizeRoute roles={routes.tenantDetails.roles} component={TenantDetails} />,
      },
      {
        path: routes.customer.path,
        element: <AuthorizeRoute roles={routes.customer.roles} component={Customer} />,
      },
      {
        path: routes.folder.path,
        element: <AuthorizeRoute roles={routes.folder.roles} component={Folder} />,
      },
      {
        element: <AuthorizeRoute roles={routes.folder.roles} component={FolderWrapper} />,
        children: [
          {
            path: routes.folderDetails.path,
            element: <AuthorizeRoute roles={routes.folderDetails.roles} component={FolderDetails} />,
          },
          {
            path: routes.folderDocument.path,
            element: <AuthorizeRoute roles={routes.folderDocument.roles} component={Document} />,
          },
          {
            path: routes.folderDocumentWithPdf.path,
            element: <AuthorizeRoute roles={routes.folderDocument.roles} component={Document} />,
          },
          {
            path: routes.folderDocumentWithPdfAndId.path,
            element: <AuthorizeRoute roles={routes.folderDocument.roles} component={Document} />,
          },
          {
            path: routes.folderSef.path,
            element: <AuthorizeRoute roles={routes.folderSef.roles} component={FolderSef} />,
          },
          {
            path: routes.folderEmail.path,
            element: <AuthorizeRoute roles={routes.folderEmail.roles} component={Email} />,
          },
          // {
          //   path: routes.folderHistory.path,
          //   element: <AuthorizeRoute roles={routes.folderHistory.roles} component={FolderHistory} />,
          // },
        ],
      },
      {
        path: routes.myActivities.path,
        element: <AuthorizeRoute roles={routes.myActivities.roles} component={MyActivities} />,
        children: [
          {
            path: routes.newDocuments.path,
            element: <AuthorizeRoute roles={routes.newDocuments.roles} component={NewDocuments} />,
          },
          {
            path: routes.readyForControlDocuments.path,
            element: (
              <AuthorizeRoute roles={routes.readyForControlDocuments.roles} component={ReadyForControlDocuments} />
            ),
          },
          {
            path: routes.readyForSendingDocuments.path,
            element: (
              <AuthorizeRoute roles={routes.readyForSendingDocuments.roles} component={ReadyForSendingDocuments} />
            ),
          },
          {
            path: routes.sentDocuments.path,
            element: <AuthorizeRoute roles={routes.sentDocuments.roles} component={SentDocuments} />,
          },
          {
            path: routes.rejectedDocuments.path,
            element: <AuthorizeRoute roles={routes.rejectedDocuments.roles} component={RejectedDocuments} />,
          },
          {
            path: routes.undispatched.path,
            element: <AuthorizeRoute roles={routes.undispatched.roles} component={Undispatched} />,
          },
          {
            element: <AuthorizeRoute roles={routes.undispatched.roles} component={FolderWrapper} />,
            children: [
              {
                path: routes.undispatchedInvoice.path,
                element: <AuthorizeRoute roles={routes.undispatchedInvoice.roles} component={UndispatchedInvoice} />,
              },
              // {
              //   path: routes.undispatchedHistory.path,
              //   element: <AuthorizeRoute roles={routes.undispatchedHistory.roles} component={UndispatchedHistory} />,
              // },
              {
                path: routes.undispatchedDetails.path,
                element: <AuthorizeRoute roles={routes.undispatchedDetails.roles} component={UndispatchedDetails} />,
              },
              {
                path: routes.undispatchedDocument.path,
                element: <AuthorizeRoute roles={routes.undispatchedDocument.roles} component={UndispatchedDocument} />,
              },
              {
                path: routes.undispatchedDocumentWithPdf.path,
                element: <AuthorizeRoute roles={routes.undispatchedDocument.roles} component={UndispatchedDocument} />,
              },
              {
                path: routes.undispatchedDocumentWithPdfAndId.path,
                element: <AuthorizeRoute roles={routes.undispatchedDocument.roles} component={UndispatchedDocument} />,
              },
              {
                path: routes.undispatchedEmail.path,
                element: <AuthorizeRoute roles={routes.undispatchedEmail.roles} component={Email} />,
              },
            ],
          },
        ],
      },
      {
        path: routes.user.path,
        element: <AuthorizeRoute roles={routes.user.roles} component={User} />,
      },
      {
        path: routes.activityLogs.path,
        element: <AuthorizeRoute roles={routes.activityLogs.roles} component={ActivityLogs} />,
      },
      {
        path: routes.activityLog.path,
        element: <AuthorizeRoute roles={routes.activityLog.roles} component={ActivityLog} />,
      },
      {
        path: routes.groupVatRecording.path,
        element: (
          <AuthorizeRoute
            roles={routes.groupVatRecording.roles}
            component={GroupVatRecording}
            flag={FeatureFlags.VatRecordingGUI}
          />
        ),
      },
      {
        path: routes.groupVatRecordingDetails.path,
        element: (
          <AuthorizeRoute
            roles={routes.groupVatRecordingDetails.roles}
            component={GroupVatRecordingDetails}
            flag={FeatureFlags.VatRecordingGUI}
          />
        ),
      },
      {
        path: routes.individualVatRecording.path,
        element: (
          <AuthorizeRoute
            roles={routes.individualVatRecording.roles}
            component={IndividualVatRecording}
            flag={FeatureFlags.VatRecordingGUI}
          />
        ),
      },
      {
        path: routes.individualVatRecordingDetails.path,
        element: (
          <AuthorizeRoute
            roles={routes.individualVatRecordingDetails.roles}
            component={IndividualVatRecordingDetails}
            flag={FeatureFlags.VatRecordingGUI}
          />
        ),
      },
      {
        path: routes.companySettings.path,
        element: <AuthorizeRoute roles={routes.companySettings.roles} component={CompanySettings} />,
      },
      {
        path: routes.approvals.path,
        element: <AuthorizeRoute roles={routes.approvals.roles} component={Approvals} />,
      },
      {
        path: routes.approvalFlows.path,
        element: <AuthorizeRoute roles={routes.approvalFlows.roles} component={ApprovalFlows} />,
      },
      {
        path: routes.triggers.path,
        element: <AuthorizeRoute roles={routes.triggers.roles} component={Triggers} />,
      },
      {
        path: routes.triggerFlows.path,
        element: <AuthorizeRoute roles={routes.triggerFlows.roles} component={TriggerFlows} />,
      },
      {
        path: routes.settingsEmailTemplate.path,
        element: <AuthorizeRoute roles={routes.settingsEmailTemplate.roles} component={EmailTemplate} />,
      },
      {
        path: routes.channelSettings.path,
        element: <AuthorizeRoute roles={routes.channelSettings.roles} component={ChannelSettings} />,
      },
      {
        path: routes.reportEmail.path,
        element: <AuthorizeRoute roles={routes.reportEmail.roles} component={Report} />,
      },
      {
        path: routes.helpManual.path,
        element: <AuthorizeRoute roles={routes.helpManual.roles} component={Manual} />,
      },
      {
        path: routes.helpContact.path,
        element: <AuthorizeRoute roles={routes.helpContact.roles} component={Contact} />,
      },
      {
        path: routes.audit.path,
        element: <AuthorizeRoute roles={routes.audit.roles} component={Audit} />,
      },
      {
        path: routes.userManual.path,
        element: <AuthorizeRoute roles={routes.userManual.roles} component={UserManual} />,
      },
      {
        path: '*',
        element: <AuthorizeRoute roles={routes.helpContact.roles} component={NotFound} />,
      },
      {
        path: routes.dashboard.path,
        element: <AuthorizeRoute roles={routes.dashboard.roles} component={Dashboard} />,
      },
    ],
  },
  {
    path: '/auth',
    element: <HomepageLayout />,
    errorElement: <ErrorElement />,
    children: [
      {
        path: routes.login.path,
        element: <Login />,
      },
    ],
  },

  {
    path: '/reject',
    element: <HomepageLayout />,
    errorElement: <ErrorElement />,
    children: [
      {
        path: routes.reject.path,
        element: <Reject />,
      },
    ],
  },
])
