import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'

import { reducer as modalReducer } from 'services/modal/slice'
import { reducer as accountReducer } from 'services/account/slice'
import { reducer as activityLogReducer } from 'services/activityLog/slice'
import { reducer as approvalsReducer } from 'services/approvals/slice'
import { reducer as authReducer } from 'services/auth/slice'
import { reducer as companyInfoReducer } from 'services/companyInfo/slice'
import { reducer as customerReducer } from 'services/customer/slice'
import { reducer as dashboardReducer } from 'services/dashboard/slice'
import { reducer as documentReducer } from 'services/document/slice'
import { reducer as emailReducer } from 'services/email/slice'
import { reducer as folderReducer } from 'services/folder/slice'
import { reducer as folderHistoryReducer } from 'services/folderHistory/slice'
import { reducer as folderSefReducer } from 'services/folderSef/slice'
import { reducer as helpReducer } from 'services/help/slice'
import { reducer as invoiceReducer } from 'services/invoice/slice'
import { reducer as reportReducer } from 'services/report/slice'
import { reducer as settingsReducer } from 'services/settings/slice'
import { reducer as templateReducer } from 'services/template/slice'
import { reducer as tenantReducer } from 'services/tenant/slice'
import { reducer as toastReducer } from 'services/toast/slice'
import { reducer as triggerReducer } from 'services/triggers/slice'
import { reducer as userReducer } from 'services/user/slice'
import { reducer as userManualReducer } from 'services/userManual/slice'
import { reducer as channelSettingsReducer } from 'services/channelSettings/slice'
import { reducer as groupVatRecordingReducer } from 'services/groupVatRecording/slice'
import { reducer as individualVatRecordingReducer } from 'services/individualVatRecording/slice'

const rootReducers = {
  modal: modalReducer,
  account: accountReducer,
  user: userReducer,
  customer: customerReducer,
  auth: authReducer,
  invoice: invoiceReducer,
  document: documentReducer,
  folder: folderReducer,
  folderHistory: folderHistoryReducer,
  email: emailReducer,
  template: templateReducer,
  settings: settingsReducer,
  report: reportReducer,
  companyInfo: companyInfoReducer,
  folderSef: folderSefReducer,
  help: helpReducer,
  approvals: approvalsReducer,
  tenant: tenantReducer,
  trigger: triggerReducer,
  activityLog: activityLogReducer,
  userManual: userManualReducer,
  toast: toastReducer,
  dashboard: dashboardReducer,
  channelSettings: channelSettingsReducer,
  groupVatRecording: groupVatRecordingReducer,
  individualVatRecording: individualVatRecordingReducer,
}

// const enhancer = composeWithDevTools(applyMiddleware(routerMiddleware(history), ...middleware))

const createRootReducer = () =>
  combineReducers({
    // router: connectRouter(history),
    ...rootReducers,
  })

// const listenerMiddleware = createListenerMiddleware()

// listenerMiddleware.startListening({
//   actionCreator: 'addErrorToast',
//   effect: async (action, listenerApi) => {
//     action?.meta?.requestStatus === 'rejected' &&
//       action?.payload?.status !== 401 &&
//       listenerApi.dispatch(addToast({ type: 'failure', message: action.payload?.errorCode }))
//   },
// })

export const store = configureStore({
  reducer: createRootReducer(),
  // middleware: [...getDefaultMiddleware(), routerMiddleware(history), ...middleware],

  // middleware: getDefaultMiddleware => getDefaultMiddleware().concat(routerMiddleware(history)).concat(middleware),
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ['meta.arg'],
      },
    }),
    // routerMiddleware(history),
  ],
})
