import { isAuthorized } from 'auth/AuthorizeService'
import { roles } from 'auth/roles'
import { folderStatus, MONTHS, QUARTERS } from './constants'
import qs from 'qs'
import { withPrefix } from 'i18n/translation'
const pt = withPrefix('common')

export const getStatusClass = (statusId, ignorBadgePillStyle) => {
  let statusClassAndColor = {
    badgeColor: ignorBadgePillStyle ? ' ' : 'badge rounded-pill ',
    textColor: '',
    color: '',
    backgroundColor: '',
  }
  switch (statusId) {
    case 1:
      statusClassAndColor.badgeColor += 'badge-default-neutral-grey'
      break
    case 100:
      statusClassAndColor.badgeColor += 'badge-new'
      statusClassAndColor.textColor = 'text-new'
      statusClassAndColor.color = '#0a487e'
      statusClassAndColor.backgroundColor = '#bee4f5'
      break
    case 200:
      statusClassAndColor.badgeColor += 'badge-ready-for-control'
      statusClassAndColor.textColor = 'text-ready-for-control'
      statusClassAndColor.color = '#0c3358'
      statusClassAndColor.backgroundColor = '#7ecdf1'
      break
    case 300:
      statusClassAndColor.badgeColor += 'badge-ready-for-sending'
      statusClassAndColor.textColor = 'text-ready-for-sending'
      statusClassAndColor.color = '#255611'
      statusClassAndColor.backgroundColor = '#dcedc0'
      break
    case 400:
      statusClassAndColor.badgeColor += 'badge-ready-for-audit'
      statusClassAndColor.textColor = 'text-ready-for-audit'
      statusClassAndColor.color = '#430d8d'
      statusClassAndColor.backgroundColor = '#d5c2ef'
      break
    case 500:
      statusClassAndColor.badgeColor += 'badge-sent'
      statusClassAndColor.textColor = 'text-sent'
      statusClassAndColor.color = '#153e04'
      statusClassAndColor.backgroundColor = '#a4d348'
      break
    case 600: //? missing rejected colors
      statusClassAndColor.badgeColor += 'badge-rejected'
      statusClassAndColor.textColor += 'text-rejected'
      statusClassAndColor.color = '#600b0b'
      statusClassAndColor.backgroundColor = '#f98d8d'
      break
    case 700:
      statusClassAndColor.badgeColor += 'badge-audited'
      statusClassAndColor.textColor = 'text-audited'
      statusClassAndColor.color = '#ccceee'
      statusClassAndColor.backgroundColor = '#3245a1'
      break
    case 800:
      statusClassAndColor.badgeColor += 'badge-audit-in-progress'
      statusClassAndColor.textColor = 'text-audit-in-progress'
      statusClassAndColor.color = '#3d0d56'
      statusClassAndColor.backgroundColor = '#c39fe3'
      break
    default:
      statusClassAndColor.badgeColor += 'badge-default-neutral-grey'
      break
  }
  return statusClassAndColor
}

export const formatDecimalsList = (data, fields, minimumFractionDigits) => {
  const formatedData =
    data &&
    data.map(x => {
      const obj = { ...x }
      for (let index in fields) {
        if (obj[fields[index]] || obj[fields[index]] >= 0) {
          obj[fields[index]] = x[fields[index]].toLocaleString('sr-sl', {
            minimumFractionDigits: minimumFractionDigits,
          }) //toLocaleString('currentLocale')
        }
      }
      return obj
    })
  return formatedData
}

export const formatDecimalsObject = (object, fields, minimumFractionDigits) => {
  const obj = object
  for (let index in fields) {
    if (object[fields[index]] || object[fields[index]] >= 0) {
      obj[fields[index]] = object[fields[index]].toLocaleString('sr-sl', {
        minimumFractionDigits: minimumFractionDigits,
      })
    }
  }
  return obj
}

export const validateEmail = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}

export const getUniqueArrayofObjects = arr =>
  arr.filter((value, index) => {
    return (
      index ===
      arr.findIndex(obj => {
        return obj?.id === value?.id
      })
    )
  })

export const invertObject = obj =>
  Object.keys(obj).reduce((ret, key) => {
    ret[obj[key]] = key
    return ret
  }, {})

export const filterUsersByRole = (users, role) =>
  users.filter(user => user?.roles.map(role => role?.toLowerCase()).includes(role?.toLowerCase()))

export const convertKbToMb = data =>
  data > 1024 ? `${Math.round((data / 1024) * 100) / 100} MB` : `${Math.round(data * 100) / 100} KB`

export const ConditionalWrap = ({ condition, wrap, children }) => (condition ? wrap(children) : children)

export const isInStage = (approverInfoModel, stageName) => {
  const inStageNames = approverInfoModel?.approverStages?.map(x => x.approvesInStage?.stageName)
  return inStageNames?.some(name => name?.toLowerCase() === stageName?.toLowerCase())
}

export const getFormData = values => {
  const formData = new FormData()
  for (let key in values) {
    Array.isArray(values[key])
      ? values[key].forEach(value => formData.append(key, value))
      : formData.append(key, values[key])
  }
  return formData
}
export const getQueryParams = search => qs.parse(search?.toString(), { ignoreQueryPrefix: true })

export const getTableState = (searchParams, initialTableState) => {
  const params = groupParamsByKey(searchParams)
  return { ...initialTableState, ...params }
}

export const getUserRolesByCompany = userCompanies => {
  if (!userCompanies || userCompanies?.length === 0) return []
  const getDefaultCompany = () => {
    const defaultCompanyId = userCompanies.length !== 0 && userCompanies[0].companyId
    defaultCompanyId && localStorage.setItem('companyId', defaultCompanyId)

    return defaultCompanyId
  }

  let companyId = localStorage.getItem('companyId')

  // if (!companyId) {
  //   companyId = getDefaultCompany()
  //   !isLoggingIn && dispatch(logout(history))
  // }
  const userRoles = userCompanies && companyId && userCompanies.find(c => c.companyId === parseInt(companyId))
  if (!userRoles) {
    //if user doesn't have company specified in localStorage then take first one
    const defaultCompanyId = getDefaultCompany()
    return userCompanies && userCompanies[defaultCompanyId]?.roles
  }
  return userRoles?.roles
}

export const removeNullUndefined = obj =>
  Object.entries(obj).reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {})

export const groupParamsByKey = params =>
  [...params.entries()].reduce((acc, tuple) => {
    // getting the key and value from each tuple
    const [key, val] = tuple
    if (Object.prototype.hasOwnProperty.call(acc, key)) {
      // if the current key is already an array, we'll add the value to it
      if (Array.isArray(acc[key])) {
        acc[key] = [...acc[key], val]
      } else {
        // if it's not an array, but contains a value, we'll convert it into an array
        // and add the current value to it
        acc[key] = [acc[key], val]
      }
    } else {
      // plain assignment if no special case is present
      acc[key] = val
    }
    return acc
  }, {})

export const getArray = val => {
  return Array.isArray(val) ? val.map(x => +x) : [+val]
}

export const fromKBtoMB = data => Math.round((data / 1024) * 100) / 100

export const convertEnumIntoModel = enumObject =>
  Object.keys(enumObject).reduce((o, key) => ({ ...o, [key]: false }), {})

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result.replace('data:', '').replace(/^.+,/, ''))
    reader.onerror = reject
  })

export const handleEmptyValues = values => {
  const trimmed = {}
  Object.keys(values).forEach(k => {
    if (typeof values[k] === 'string') {
      if (values[k].trim()?.length > 0) trimmed[k] = values[k].trim()
    } else {
      trimmed[k] = values[k]
    }
  })
  return trimmed
}

export const objectSwap = obj => {
  const ret = {}
  Object.keys(obj).forEach(key => {
    ret[obj[key]] = key
  })
  return ret
}

export const beginsWithVowel = word => {
  return word && ['a', 'e', 'i', 'o', 'u'].indexOf(word[0].toLowerCase()) !== -1
}

export const cleanObject = obj => {
  const checkValue = val => {
    if (typeof val === 'string') return !!val.trim()
    if (val === 0 || typeof val === 'boolean') return true
    return !!val
  }
  // eslint-disable-next-line no-unused-vars
  return Object.fromEntries(Object.entries(obj).filter(([_, val]) => checkValue(val)))
}

export const stageNamesIncludesSend = approverInfoModel => {
  const inStageNames = approverInfoModel?.approverStages?.map(x => x.approvesInStage?.stageName)
  return inStageNames?.some(name => name === 'Send')
}

export const stageNamesIncludesActiveReferent = (approverInfoModel, statusId) => {
  const inStageNames = approverInfoModel?.approverStages?.map(x => x.approvesInStage?.stageName)
  const inStateNames = approverInfoModel?.approverStages?.map(x => x.approverState?.stateName)
  return (
    inStageNames?.some(name => name === 'Referent') &&
    inStateNames?.some(name => name === 'Active') &&
    statusId === folderStatus.Rejected
  )
}

export const checkIfIsAuthorizedForSEF = (userRoles, approverInfoModel) =>
  isAuthorized(userRoles, [roles.AUDITOR]) ||
  (isAuthorized(userRoles, [roles.SENDER]) && stageNamesIncludesSend(approverInfoModel))

export const chcekIfReferentIsAuthorizedForSEF = (userRoles, approverInfoModel, statusId) =>
  isAuthorized(userRoles, [roles.REFERENT]) &&
  (stageNamesIncludesSend(approverInfoModel) || stageNamesIncludesActiveReferent(approverInfoModel, statusId))

export const formatClassNames = (headerClassses, cellClasses) => {
  const formatParameters = par => {
    if (!par) return null
    if (typeof par === 'string') return par
    if (Array.isArray(par)) return par.join(' ')
  }
  //prettier-ignore
  return ({
    meta: {
      headerProps: {
        classes: formatParameters(headerClassses),
      },
      cellProps: {
        classes: formatParameters(cellClasses),
      },
    },
  })
}

export const derivePeriodRange = vatPeriod => {
  if (vatPeriod >= 1 && vatPeriod <= 12) {
    return 'monthly'
  } else if (vatPeriod >= 13 && vatPeriod <= 16) {
    return 'quarterly'
  } else {
    return ''
  }
}

export const getPeriodLabel = period => {
  if (period === 0 || period > 16) return
  if (period < 13) return pt(MONTHS[period])
  else {
    return pt(QUARTERS[period])
  }
}
