import { get as getJson, post as postJson } from 'api'

export const getList = async request => getJson('/VatRecording/v2/individual/GetListIndividual', request)

export const getListTotalSize = async request => getJson('/VatRecording/v2/individual/GetIndividualListTotal', request)

export const get = async id => getJson(`/VatRecording/v2/Individual/GetIndividual/${id}`)

export const cancel = async id => {
  return postJson(`/VatRecording/v2/Individual/cancel/${id}`)
}

export const resendIndividual = async id => {
  return postJson(`/VatRecording/v2/Individual/ResendIndividual?id=${id}`)
}
