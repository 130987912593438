import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import moment from 'moment'

import * as api from './api'

export const get = createAsyncThunk('individualVatRecording/GET', async (individualVatId, thunkAPI) => {
  const { data, error } = await api.get(individualVatId)
  if (error) return thunkAPI.rejectWithValue(error)
  return data
})

export const getList = createAsyncThunk('individualVatRecording/GET_LIST', async (request, thunkAPI) => {
  const formattedValues = {
    createdFrom: request.createdFrom !== '' ? moment(request.createdFrom).format('YYYY-MM-DDTHH:mm:ss[Z]') : null,
    createdTo: request.createdTo !== '' ? moment(request.createdTo).format('YYYY-MM-DDTHH:mm:ss[Z]') : null,
    statusTimeFrom:
      request.statusTimeFrom && request.statusTimeFrom !== ''
        ? moment(request.statusTimeFrom).format('YYYY-MM-DDTHH:mm:ss[Z]')
        : null,
    statusTimeTo:
      request.statusTimeTo && request.statusTimeTo !== ''
        ? moment(request.statusTimeTo).format('YYYY-MM-DDTHH:mm:ss[Z]')
        : null,
  }
  const { data, error } = await api.getList({ ...request, ...formattedValues })
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    thunkAPI.dispatch(getListTotalSize({ ...request, ...formattedValues }))
    return data
  }
})

export const getListTotalSize = createAsyncThunk('individualVatRecording/GET_LIST_TOTALSIZE', async (id, thunkAPI) => {
  // debugger
  const { data, error } = await api.getListTotalSize(id)
  if (error) return thunkAPI.rejectWithValue(error)
  return data
})

export const cancel = createAsyncThunk('individualVatRecording/CANCEL', async (individualVatId, thunkAPI) => {
  // debugger
  const state = thunkAPI.getState()
  const id = state.individualVatRecording.model.id
  const { data, error } = await api.cancel(individualVatId)

  if (error) return thunkAPI.rejectWithValue(error)
  else {
    thunkAPI.dispatch(get(id))
    return data
  }
})

export const resendIndividual = createAsyncThunk('individualVatRecording/RESENDINDIVIDUAL', async (id, thunkAPI) => {
  const { data, error } = await api.resendIndividual(id)

  if (error) return thunkAPI.rejectWithValue(error)
  return data
})

export const slice = createSlice({
  name: 'individualVatRecording',
  initialState: {
    list: [],
    isListLoading: false,
    error: null,
    totalSize: 0,
    model: null,
    isModelLoading: false,
    isCancel: false,
    isResendIndividual: false,
  },
  reducers: {
    resetIsCancel: state => {
      state.isCancel = false
    },
    resetIsResendIndividual: state => {
      state.isResendIndividual = false
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getList.pending, state => {
        state.isListLoading = true
        state.error = null
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.isListLoading = false
        state.list = action.payload
      })
      .addCase(getList.rejected, (state, action) => {
        state.isListLoading = false
        state.error = action.payload
      })
      .addCase(getListTotalSize.pending, state => {
        state.error = null
      })
      .addCase(getListTotalSize.fulfilled, (state, action) => {
        state.totalSize = action.payload
      })
      .addCase(getListTotalSize.rejected, (state, action) => {
        state.error = action.payload
      })
      .addCase(get.pending, state => {
        state.isModelLoading = true
        state.error = null
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isModelLoading = false
        state.model = action.payload
      })
      .addCase(get.rejected, (state, action) => {
        state.isModelLoading = false
        state.error = action.payload
      })
      .addCase(cancel.pending, state => {
        state.isCancel = false
        state.isResendIndividual = false
        state.error = null
      })
      .addCase(cancel.fulfilled, (state, action) => {
        state.isCancel = true
      })
      .addCase(cancel.rejected, (state, action) => {
        state.error = action.payload
        state.isCancel = false
      })
      .addCase(resendIndividual.pending, state => {
        state.isResendIndividual = false
        state.isCancel = false
        state.error = null
      })
      .addCase(resendIndividual.fulfilled, (state, action) => {
        state.isResendIndividual = true
      })
      .addCase(resendIndividual.rejected, (state, action) => {
        state.error = action.payload
        state.isResendIndividual = false
      })
  },
})

export const { resetIsCancel, resetIsResendIndividual } = slice.actions

export const listSelector = state => state.individualVatRecording.list
export const isListLoadingSelector = state => state.individualVatRecording.isListLoading
export const errorSelector = state => state.individualVatRecording.error
export const totalSizeSelector = state => state.individualVatRecording.totalSize
export const modelSelector = state => state.individualVatRecording.model

export const isModelLoadingSelector = state => state.individualVatRecording.isModelLoading
export const isCancelSelector = state => state.individualVatRecording.isCancel
export const isResendIndividualSelector = state => state.individualVatRecording.isResendIndividual

export const reducer = slice.reducer
