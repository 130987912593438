import { get as getJson, post as postJson } from 'api'

export const getList = async request => getJson('/VatRecording/v2/group/GetListGroup', request)

export const getListTotalSize = async request => getJson('/VatRecording/v2/group/GetGroupListTotal', request)

export const get = async id => getJson(`/VatRecording/v2/group/GetGroup/${id}`)

export const cancel = async groupVatId => {
  return postJson(`/VatRecording/v2/group/cancel/${groupVatId}`)
}

export const resendGroup = async id => {
  return postJson(`/VatRecording/v2/group/ResendGroup?id=${id}`)
}
