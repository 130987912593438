import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import moment from 'moment'

import * as api from './api'

export const get = createAsyncThunk('groupVatRecording/GET', async (groupVatId, thunkAPI) => {
  const { data, error } = await api.get(groupVatId)
  if (error) return thunkAPI.rejectWithValue(error)
  return data
})
export const getList = createAsyncThunk('groupVatRecording/GET_LIST', async (request, thunkAPI) => {
  const formattedValues = {
    createdFrom: request.createdFrom !== '' ? moment(request.createdFrom).format('YYYY-MM-DDTHH:mm:ss[Z]') : null,
    createdTo: request.createdTo !== '' ? moment(request.createdTo).format('YYYY-MM-DDTHH:mm:ss[Z]') : null,
    statusTimeFrom:
      request.statusTimeFrom && request.statusTimeFrom !== ''
        ? moment(request.statusTimeFrom).format('YYYY-MM-DDTHH:mm:ss[Z]')
        : null,
    statusTimeTo:
      request.statusTimeTo && request.statusTimeTo !== ''
        ? moment(request.statusTimeTo).format('YYYY-MM-DDTHH:mm:ss[Z]')
        : null,
  }

  const { data, error } = await api.getList({ ...request, ...formattedValues })
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    thunkAPI.dispatch(getListTotalSize({ ...request, ...formattedValues }))
    return data
  }
})

export const getListTotalSize = createAsyncThunk('groupVatRecording/GET_LIST_TOTALSIZE', async (id, thunkAPI) => {
  // debugger

  const { data, error } = await api.getListTotalSize(id)
  if (error) return thunkAPI.rejectWithValue(error)
  return data
})

export const cancel = createAsyncThunk('groupVatRecording/CANCEL', async (groupVatId, thunkAPI) => {
  const state = thunkAPI.getState()
  const id = state.groupVatRecording.model.id
  const { data, error } = await api.cancel(groupVatId)
  // debugger
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    thunkAPI.dispatch(get(id))
    return data
  }
})

export const resendGroup = createAsyncThunk('groupVatRecording/RESENDGROUP', async (id, thunkAPI) => {
  const { data, error } = await api.resendGroup(id)
  // debugger
  if (error) return thunkAPI.rejectWithValue(error)
  return data
})

export const slice = createSlice({
  name: 'groupVatRecording',
  initialState: {
    list: [],
    isListLoading: false,
    error: null,
    totalSize: 0,
    model: null,
    isModelLoading: false,
    isCancel: false,
    isResendGroup: false,
  },
  reducers: {
    resetIsCancel: state => {
      state.isCancel = false
    },
    resetIsResendGroup: state => {
      state.isResendGroup = false
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getList.pending, state => {
        state.isListLoading = true
        state.error = null
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.isListLoading = false
        state.list = action.payload
      })
      .addCase(getList.rejected, (state, action) => {
        state.isListLoading = false
        state.error = action.payload
      })
      .addCase(getListTotalSize.pending, state => {
        state.error = null
      })
      .addCase(getListTotalSize.fulfilled, (state, action) => {
        state.totalSize = action.payload
      })
      .addCase(getListTotalSize.rejected, (state, action) => {
        state.error = action.payload
      })
      .addCase(get.pending, state => {
        state.isModelLoading = true
        state.error = null
      })
      .addCase(get.fulfilled, (state, action) => {
        state.isModelLoading = false
        state.model = action.payload
      })
      .addCase(get.rejected, (state, action) => {
        state.isModelLoading = false
        state.error = action.payload
      })
      .addCase(cancel.pending, state => {
        state.isCancel = false
        state.isResendGroup = false
        state.error = null
      })
      .addCase(cancel.fulfilled, (state, action) => {
        state.isCancel = true
      })
      .addCase(cancel.rejected, (state, action) => {
        state.error = action.payload
        state.isCancel = false
      })
      .addCase(resendGroup.pending, state => {
        state.isResendGroup = false
        state.isCancel = false
        state.error = null
      })
      .addCase(resendGroup.fulfilled, (state, action) => {
        state.isResendGroup = true
      })
      .addCase(resendGroup.rejected, (state, action) => {
        state.error = action.payload
        state.isResendGroup = false
      })
  },
})

export const { resetIsCancel, resetIsResendGroup } = slice.actions

export const listSelector = state => state.groupVatRecording.list
export const isListLoadingSelector = state => state.groupVatRecording.isListLoading
export const errorSelector = state => state.groupVatRecording.error
export const totalSizeSelector = state => state.groupVatRecording.totalSize
export const modelSelector = state => state.groupVatRecording.model
export const isModelLoadingSelector = state => state.groupVatRecording.isModelLoading
export const isCancelSelector = state => state.groupVatRecording.isCancel
export const isResendGroupSelector = state => state.groupVatRecording.isResendGroup

export const reducer = slice.reducer
